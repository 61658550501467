import React, { useRef, useContext, useState, lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/Home/Hero';
import { Testimonials } from '../components/Testimonials';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
// import { Intro } from '../components/Home/Intro';
// import { Offerings } from '../components/Home/Offerings';
import { HeroModal } from '../components/Home/Hero/HeroModal';
import { CustomerLogos } from '../components/CustomerLogos';
import { HomeBody } from '../components/Home/HomeBody';
import { PromotionModal } from '../components/General/PromotionModal';
import { HomeNewsBar } from '../components/Home/HomeBody/HomeNewsBar';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	testimonialTitle: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '2rem',
		marginTop: '2rem',
	},

	// centerBackground: {
	// 	background:
	// 		'linear-gradient(339.51deg, #002D5C 26.73%, #055291 46.94%, #2F7FC1 67.6%, #002D5C 88.26%)',
	// 	padding: '6rem 0',
	// },

	formCont: {
		padding: '12rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '8rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '5rem 0',
		},
	},
	testimonialWrapper: {
		background: 'transparent',
		padding: '2rem 0',
		position: 'relative',
		// marginTop: '-4rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-2rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-1rem',
		},
	},
}));

const IndexPage = ({ data, location }) => {
	const home = data.home.edges[0].node;
	const classes = useStyles();
	const formRef = useRef();

	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const [heroModalOpen, setHeroModalOpen] = useState(false);

	const {
		metaTitle,
		metaDescription,
		hero,
		pardotUrl,
		contactForm,
		formBgImage,
		homeLinksCallout,
		homeCta,
		resources,
		resourceTitle,
		_rawResourceBody,
		homeIntro,
	} = home;

	const { industries } = data;
	const sm = useMediaQuery('(max-width: 600px)');
	const xs = useMediaQuery('(max-width: 481px)');

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero
				hero={hero}
				homeHeroVideo={hero.homeHeroVideo}
				setHeroModalOpen={setHeroModalOpen}
				industries={industries}
			/>
			{/* <Container>
				<HomeNewsBar
					background={homeNewsBar.newsBarBackground}
					header={homeNewsBar._rawNewsBarBlockHeader}
					newsBarLink={homeNewsBar.newsBarLink}
					newsBarText={homeNewsBar.newsBarText}
					newsBarImage={homeNewsBar.newsBarImage}
					internalLink={homeNewsBar.internalLink}
					simulations
				/>
			</Container> */}

			<HomeBody
				intro={homeIntro}
				body={home}
				homeCta={homeCta}
				homeLinksCallout={homeLinksCallout}
				home
			/>
			{/* <WaveDownSVG height='213' width='100%' fill='white' /> */}
			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<Form
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					location={location}
				/>
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='#FFFFFF' /> */}
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
					noWave
				/>
			</Container>
			<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />

			<HeroModal
				open={heroModalOpen}
				setHeroModalOpen={setHeroModalOpen}
				homeHeroVideo={hero.homeHeroVideo}
				playing
			/>
			{/* {!!promotionModal ? (
				<PromotionModal promotionModal={promotionModal} />
			) : null} */}
		</>
	);
};

export const query = graphql`
	query HomePageQuery {
		home: allSanityHome {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						homeHeroVideo
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						mobileBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						videoImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					homeIntro {
						_rawLeftContent
						rightSideBlurbs {
							title
							icon
							header
							body
							accentColor {
								hexValue
							}
							link
						}
					}
					statBandBackground {
						asset {
							gatsbyImageData
						}
					}
					statBand {
						_rawStats
					}
					featureSection {
						_key
						title
						cardTitle
						cardFaIcon
						cardIconColor {
							hexValue
						}
						bodySubHeader
						bodyHeader
						image {
							featureImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						videoVariant
						androidLink
						iosLink
						qbLink
						thumbnailVideoUrl
						ctaText
						ctaLink
						_rawBodyCopy
						internalLink
						links {
							title
							cardTitle
							slug {
								current
							}
							marketingLinkIcon
							linkIconColor {
								hexValue
							}
							internalLink
						}
					}
					homeCta {
						ctaHeader
						ctaSubheader
						ctaText
						ctaLink
						internalLink
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					testimonialHeader
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					testimonials {
						title
						header
						testimonialText
						nameAndTitle
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					customerLogosHeader
					customerLogos {
						title
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					pardotUrl
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
				}
			}
		}
		industries: allSanityIndustries {
			edges {
				node {
					_id
					_key
					title
					metaTitle
					metaDescription
					homeHeroPillTitle
					_rawSynopsis
					slug {
						current
					}
					primary
					accentColor {
						hexValue
					}
					faIcon
				}
			}
		}
	}
`;

export default IndexPage;
